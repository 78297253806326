//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                      from 'react';
import _                          from 'lodash';
import { bindActionCreators }     from 'redux';
import { compose }                from 'redux';
import classNames                 from 'classnames';
import { connect }                from 'react-redux';
import I18n                       from 'i18next';
import moment                     from 'moment';
import ReactHtmlParser            from 'react-html-parser';
import appStyles                  from '@/styles.module.scss';
import ComponentHelper            from '@/helper/ComponentHelper';
import Ids                        from '@/constants/Ids';
import Navigation                 from '@/helper/Navigation';
import PropTypes                  from '@/components/PropTypes';
import Routes                     from '@/constants/Routes';
import StateHelper                from '@/helper/State';
import State                      from '@/helper/State';
import { CompanyActions }         from '@/store/actions/company';
import { MachineActions }         from '@/store/actions/machine';
import { OverlayActions }         from '@/store/actions/ovleray';
import FavoriseCompanyButton      from '@/components/connected/FavoriseCompanyButton';
import { MessageRequestActions }  from '@/store/actions/messageRequest';
import HeadlineMedium             from '@/components/stateless/atomic/HeadlineMedium';
import EntityLabelSize            from '@/components/stateless/composed/EntityLabel/EntityLabelSize';
import { EntityLabel }            from '@/components/stateless/composed/EntityLabel';
import TextInput                  from '@/components/stateless/atomic/TextInput';
import TextInputSize              from '@/components/stateless/atomic/TextInput/TextInputSize';
import ColorButton                from '@/components/stateless/atomic/ColorButton';
import ColorButtonTheme           from '@/components/stateless/atomic/ColorButton/ColorButtonTheme';
import { ContentHeaderStateless } from '@/components/stateless/composed/ContentHeaderStateless';
import * as Api                   from '@/api';
import Route                      from '@/helper/Route';
import AlertBoxManager            from '@/components/connected/AlertBoxManager';
import ColorBoxColor              from '@/components/stateless/atomic/ColorBox/ColorBoxColor';
import ColorBox                   from '@/components/stateless/atomic/ColorBox';
import { CollapsableTagList }     from '@/components/stateless/composed/CollapsableTagList';
import TagList                    from '@/components/stateless/composed/TagList';
import Spacer                     from '@/components/stateless/atomic/Spacer';
import { ImagePreview }           from '@/components/stateless/composed/ImagePreview';
import { MessageRequestService }  from '@/components/stateless/composed/MessageRequestService';
import { Collapsable }            from '@/components/stateless/composed/Collapsable';
import { ProjectSummary }         from '@/components/stateless/atomic/ProjectSummary';
import ViewingContext             from '@/constants/ViewingContext';
import SideMenu                   from '@/components/connected/SideMenu';
import AttachmentList             from '@/components/stateless/composed/AttachmentList';
import AttachmentUpload           from '@/components/stateless/composed/AttachmentUpload';
import MessageRequestState        from '@/constants/MessageRequestState';
import ColorButtonSize            from '@/components/stateless/atomic/ColorButton/ColorButtonSize';
import MessageHelper              from '@/helper/MessageHelper';
import Company                    from '@/helper/Company';
import AutomaticTextTranslator    from '@/components/stateless/composed/AutomaticTextTranslator';
import IconType                   from '@/components/stateless/atomic/Icon/IconType';
import IconButtonTheme            from '@/components/stateless/atomic/IconButton/IconButtonTheme';
import IconTextButtonPosition     from '@/components/stateless/atomic/IconTextButton/IconTextButtonPosition';
import { IconTextButton }         from '@/components/stateless/atomic/IconTextButton';
import styles                     from './styles.module.scss';

class Screen extends React.Component {
    constructor(props) {
        super(props);

        this.fetchCompany();

        this.messagesEnd = React.createRef();
        this.state       = {
            translatedMessages: [],
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.currentCompanyId !== this.props.currentCompanyId) {
            this.fetchCompany();
        }

        if (prevProps.messageRequest?.messages?.length !== this.props.messageRequest?.messages?.length) {
            this.scrollToBottom();
        }
    }

    fetchCompany = () => {
        const id = this.props.currentCompanyId;

        this.props.setCurrentCompany({
            id,
        });
    };

    scrollToBottom = () => {
        this.messagesEnd?.current?.scrollIntoView({
            behavior: 'smooth',
        });
    };

    componentDidMount() {
        const messageRequest    = this.getMessageRequest();
        const messageRequestIri = _.get(messageRequest, 'iri');
        const messageRequestId  = _.get(messageRequest, 'id');

        this.props.setCurrentMessageRequest({
            iri: messageRequestIri,
        });
        this.props.markMessagesAsRead({
            messageRequestId,
        });
        this.scrollToBottom();
    }

    getMessageRequest = () => {
        return this.props.messageRequest;
    };

    onTranslateClicked = (message) => () => {
        const { translatedMessages } = this.state;
        const messageId              = _.get(message, 'id');

        translatedMessages.push(messageId);

        this.setState({
            translatedMessages: [
                ...translatedMessages,
            ],
        });
    };

    getTargetCompany = () => {
        const messageRequest    = this.getMessageRequest();
        const company           = _.get(messageRequest, 'company');
        const requestingCompany = _.get(messageRequest, 'requestingCompany');
        const targetCompany     = (
            MessageHelper.isOwnMessage(this.props, messageRequest) ?
                requestingCompany :
                company
        );

        return targetCompany;
    };

    getSenderPositionAndCompany = (sender) => {
        const senderPosition           = sender?.position;
        const translatedSenderPosition = I18n.t(`companyPositions.${senderPosition}`);
        const senderCompany            = sender?.workingAtCompany;

        if (
            !senderPosition ||
            !senderCompany
        ) {
            return null;
        }

        return I18n.t(
            'userCompanyPosition',
            {
                companyPosition: translatedSenderPosition,
                companyName:     senderCompany,
            },
        );
    };

    getCountryCode = (messageRequest, sender) => {
        return _.get(sender, 'country');
    };

    renderEntityLabel = (
        messageRequest,
        isOwnRequest,
        subtitle = null,
        size     = EntityLabelSize.default,
        ownIri   = null,
    ) => {
        const requestingCompany = messageRequest?.requestingCompany;
        const sender            = messageRequest?.sender;
        const company           = messageRequest?.company;
        const type              = _.get(messageRequest, '@type');
        let title               = '';
        let avatar              = null;
        let route               = null;
        let subtitleText        = null;
        let withoutLink         = false;

        if (type === 'MessageRequest') {
            const requestingCompanyIri = _.get(requestingCompany, 'iri');
            let targetCompany          = requestingCompany;

            if (requestingCompanyIri === ownIri) {
                targetCompany = company;
            }

            if (sender?.iri === ownIri) {
                targetCompany = company;
            }

            if (targetCompany) {
                const companyId   = _.get(targetCompany, 'id');
                const companySlug = _.get(targetCompany, 'slug');
                const companyUrl  = Route.getCompanyUrl(companyId, companySlug);
                route             = Route.buildRoute(Routes.company, companyUrl);
                title             = targetCompany?.name;
                subtitleText      = subtitle ?? Company.getCompanyTypesString(_.get(targetCompany, 'types'));
                avatar            = targetCompany?.logo?.path;
            } else {
                title        = sender?.name;
                subtitleText = subtitle ?? this.getSenderPositionAndCompany(sender);
                avatar       = sender?.image?.path;
                withoutLink  = true;
            }
        } else if (type === 'Message') {
            const senderIsCompany = sender?.['@type'] === 'Company';

            if (senderIsCompany) {
                const companyId   = _.get(company, 'id');
                const companySlug = _.get(company, 'slug');
                const companyUrl  = Route.getCompanyUrl(companyId, companySlug);
                route             = Route.buildRoute(Routes.company, companyUrl);
                title             = company?.name;
                subtitleText      = subtitle ?? Company.getCompanyTypesString(_.get(company, 'types'));
                avatar            = company?.logo?.path;
            } else {
                title        = sender?.name;
                subtitleText = subtitle ?? this.getSenderPositionAndCompany(sender);
                avatar       = sender?.image?.path;
                withoutLink  = true;
            }
        }

        return (
            <EntityLabel
                title={title}
                subtitle={subtitleText}
                avatar={Api.getImagePath(avatar)}
                to={route}
                iso31661Alpha2CountryCode={this.getCountryCode(messageRequest, sender)}
                withoutLink={withoutLink}
                size={size}
            />
        );
    };

    renderContentHeader = () => {
        const { ownIri }      = this.props;
        const messageRequest  = this.getMessageRequest();
        const targetCompany   = this.getTargetCompany();
        const targetCompanyId = _.get(targetCompany, 'id');
        const isOwnRequest    = MessageHelper.isOwnMessage(this.props, messageRequest);

        return (
            <ContentHeaderStateless
                leftContent={this.renderEntityLabel(
                    messageRequest,
                    isOwnRequest,
                    null,
                    EntityLabelSize.default,
                    ownIri,
                )}
                rightContent={this.renderStarButton(targetCompanyId)}
            />
        );
    };

    renderTranslationButtonOrText = (message, isHtmlMessage) => {
        if (!this.props.isMember) {
            return null;
        }

        if (isHtmlMessage) {
            return null;
        }

        if (this.isMessageTranslated(message)) {
            return (
                <p className={styles.companyMessageTranslatedWith}>
                    {I18n.t('textIsTranslatedInformation')}
                </p>
            );
        }

        return (
            <IconTextButton
                onClick={this.onTranslateClicked(message)}
                iconType={IconType.calendar}
                theme={IconButtonTheme.grayBorder}
                text={I18n.t('translate')}
                iconPosition={IconTextButtonPosition.left}
            />
        );
    };

    isMessageTranslated = (message) => {
        const { translatedMessages } = this.state;
        const messageId              = _.get(message, 'id');

        return _.includes(translatedMessages, messageId);
    };

    isMessageTextHtml = (message) => {
        const htmlRegex = /<[^>]*>/;

        return htmlRegex.test(message);
    };

    renderMessage = (message) => {
        const textMessage     = _.get(message, 'message');
        const createdAt       = _.get(message, 'createdAt');
        const attachments     = _.get(message, 'attachments', []);
        const translatedText  = (
            this.isMessageTranslated(message) ?
                <AutomaticTextTranslator
                    originalText={textMessage}
                    targetLanguage={I18n.language}
                /> :
                textMessage
        );
        const messageToRender = (
            this.isMessageTextHtml(textMessage) ?
                ReactHtmlParser(textMessage) :
                translatedText
        );

        return this.renderMessageBody(
            message,
            createdAt,
            (
                <>
                    {messageToRender}
                    <Spacer height={10} />
                    {this.renderTranslationButtonOrText(message, this.isMessageTextHtml(textMessage))}
                    <Spacer height={10} />
                    <AttachmentList
                        files={attachments}
                    />
                </>
            ),
        );
    };

    renderMessageBody = (messageRequest, createdAt, messageChild) => {
        const isOwnRequest = MessageHelper.isOwnMessage(this.props, messageRequest);

        return (
            <div
                className={classNames(
                    styles.messageContainer,
                    {
                        [styles.messageContainerRight]: isOwnRequest,
                    },
                )}
            >
                {this.renderEntityLabel(
                    messageRequest,
                    isOwnRequest,
                    moment(createdAt).format('MMMM Do, h:mm a, YYYY'),
                    EntityLabelSize.small,
                )}
                <div className={styles.messageTextContainer}>
                    {messageChild}
                </div>
            </div>
        );
    };

    onSendPressed = () => {
        this.props.sendMessage();
    };

    onSendInputChanged = (event) => {
        const text = _.get(event, 'target.value');

        this.props.setSendMessageText({
            text,
        });
    };

    onFilesChanged = (files) => {
        this.props.setSendMessageAttachments({
            attachments: files,
        });
    };

    onFileDeleted = (file) => {
        const { sendMessageAttachments } = this.props;
        const index                      = sendMessageAttachments.indexOf(file);
        const newAttachments             = [
            ...sendMessageAttachments,
        ];

        newAttachments.splice(index, 1);
        this.props.setSendMessageAttachments({
            attachments: newAttachments,
        });
    };

    onAcceptClicked = (messageRequest) => () => {
        const { iri } = messageRequest;

        this.props.setMessageRequestState({
            iri,
            state: MessageRequestState.ACCEPTED,
        });
    };

    onDeclineClicked = (messageRequest) => () => {
        const { iri } = messageRequest;

        this.props.setMessageRequestState({
            iri,
            state: MessageRequestState.DECLINED,
        });
    };

    renderSendAnswer = (messageRequest) => {
        const isNewRequest = _.eq(messageRequest.state, MessageRequestState.PENDING);
        const isOwnRequest = MessageHelper.isOwnMessage(this.props, messageRequest);

        if (
            !isNewRequest ||
            isOwnRequest
        ) {
            const attachments = this.props.sendMessageAttachments;

            return (
                <>
                    <HeadlineMedium
                        text={I18n.t('sendMessage')}
                    />
                    <div className={styles.companyMessageSendTextInput}>
                        <TextInput
                            size={TextInputSize.multilineLarge}
                            onChange={this.onSendInputChanged}
                            value={this.props.sendMessageText}
                        />
                    </div>
                    <div className={styles.sendTextButtonRow}>
                        <AttachmentUpload
                            onFilesChanged={this.onFilesChanged}
                            renderOnlyUploadButton={true}
                            files={attachments}
                        />
                        <ColorButton
                            theme={ColorButtonTheme.orange}
                            text={I18n.t('send')}
                            onClick={this.onSendPressed}
                        />
                    </div>
                    <div className={styles.attachmentListWrapper}>
                        <AttachmentUpload
                            onFileDeleted={this.onFileDeleted}
                            renderOnlyAttachmentList={true}
                            files={attachments}
                        />
                    </div>
                </>
            );
        }

        return (
            <div className={styles.messageRequestButtons}>
                <ColorButton
                    onClick={this.onAcceptClicked(messageRequest)}
                    text={I18n.t('accept')}
                    theme={ColorButtonTheme.orange}
                    size={ColorButtonSize.default}
                />
                <ColorButton
                    onClick={this.onDeclineClicked(messageRequest)}
                    text={I18n.t('decline')}
                    theme={ColorButtonTheme.transparentBorder}
                    size={ColorButtonSize.default}
                />
            </div>
        );
    };

    renderSendMessage = (messageRequest) => {
        return (
            <div className={styles.companyMessageSendContainer}>
                <AlertBoxManager />
                {this.renderSendAnswer(messageRequest)}
            </div>
        );
    };

    renderInitialMessageBody = (messageRequest) => {
        const subject         = _.get(messageRequest, 'subject');
        const message         = _.get(messageRequest, 'message');
        const images          = _.map(
            _.get(messageRequest, 'images'),
            (image) => {
                return {
                    src: Api.getImagePath(_.get(image, 'path')),
                    alt: _.get(image, 'altText'),
                };
            },
        );
        const fullMessage     = `${I18n.t('subject')}: ${subject}\n\n${message}`;
        const attachments     = _.get(messageRequest, 'attachments', []);
        const translatedText  = (
            this.isMessageTranslated(messageRequest) ?
                <AutomaticTextTranslator
                    originalText={fullMessage}
                    targetLanguage={I18n.language}
                /> :
                message
        );
        const messageToRender = (
            this.isMessageTextHtml(message) ?
                ReactHtmlParser(message) :
                translatedText
        );

        return (
            <>
                {messageToRender}
                <ImagePreview
                    images={images}
                />
                <Spacer height={10} />
                {this.renderTranslationButtonOrText(
                    messageRequest,
                    this.isMessageTextHtml(message),
                )}
                <Spacer height={10} />
                <AttachmentList
                    files={attachments}
                />
            </>
        );
    };

    renderInitialMessage = () => {
        const messageRequest = this.getMessageRequest();
        const createdAt      = _.get(messageRequest, 'createdAt');

        return this.renderMessageBody(
            messageRequest,
            createdAt,
            this.renderInitialMessageBody(messageRequest),
        );
    };

    renderProjectInfos = () => {
        const messageRequest = this.getMessageRequest();
        const { project }    = messageRequest;

        if (!project) {
            return null;
        }

        return (
            <ColorBox color={ColorBoxColor.grayLight}>
                <Collapsable
                    title={I18n.t('project')}
                    closed={true}
                >
                    <ProjectSummary project={project} />
                </Collapsable>
            </ColorBox>
        );
    };

    renderTagList = () => {
        const tags = _.get(this.getMessageRequest(), 'tagStrings');

        if (
            !tags ||
            tags.length === 0
        ) {
            return null;
        }

        return (
            <ColorBox color={ColorBoxColor.grayLight}>
                <CollapsableTagList>
                    <TagList tags={tags} />
                </CollapsableTagList>
            </ColorBox>
        );
    };

    getSideMenuRoute() {
        switch (this.props.viewingContext) {
            case ViewingContext.OWN_PROFILE:
                return Routes.myProfileMessages;
            case ViewingContext.OWN_COMPANY:
                return Routes.companyMessage;
        }

        return null;
    }

    render() {
        const sideMenuRoute = this.getSideMenuRoute();

        if (!this.getMessageRequest()) {
            return null;
        }

        const messageRequest = this.getMessageRequest();
        const sortedMessages = _.sortBy(messageRequest.messages, 'createdAt');

        return (
            <div className={appStyles.defaultContainer}>
                <div
                    className={appStyles.defaultInnerContainer}
                    id={Ids.contentContainer}
                >
                    <SideMenu
                        route={sideMenuRoute}
                        {...this.props}
                    />
                    <div className={appStyles.defaultContentContainer}>
                        {this.renderContentHeader()}
                        <div className={styles.companyMessageDetailContainer}>
                            <div className={styles.companyMessageDetailContent}>
                                {this.renderTagList()}
                                <MessageRequestService
                                    messageRequest={messageRequest}
                                    collapsable={true}
                                    field={'portfolioProduct'}
                                    sectionTitle={I18n.t('product')}
                                />
                                <MessageRequestService
                                    messageRequest={messageRequest}
                                    collapsable={true}
                                    field={'machine'}
                                    sectionTitle={I18n.t('machine')}
                                />
                                {this.renderProjectInfos()}
                                <Spacer height={20} />
                                <HeadlineMedium
                                    text={I18n.t('messages')}
                                />
                                {this.renderInitialMessage()}
                                {_.map(sortedMessages, this.renderMessage)}
                                <div
                                    ref={this.messagesEnd}
                                />
                                {this.renderSendMessage(messageRequest)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderStarButton = (companyId) => {
        return (
            <FavoriseCompanyButton
                company={companyId}
            />
        );
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

Screen.propTypes = {
    companyTypes:              PropTypes.array,
    currentCompanyId:          PropTypes.string,
    currentCompanyUrl:         PropTypes.string,
    fetchMessageRequests:      PropTypes.func,
    isMember:                  PropTypes.bool,
    markMessagesAsRead:        PropTypes.func,
    messageRequest:            PropTypes.object,
    ownIri:                    PropTypes.string,
    sendMessage:               PropTypes.func,
    sendMessageAttachments:    PropTypes.array,
    sendMessageText:           PropTypes.string,
    setCurrentCompany:         PropTypes.func,
    setCurrentMessageRequest:  PropTypes.func,
    setMessageRequestState:    PropTypes.func,
    setSendMessageAttachments: PropTypes.func,
    setSendMessageText:        PropTypes.func,
    viewingContext:            PropTypes.oneOfObjectValues(ViewingContext),
};

Screen.defaultProps = {
    companyTypes:              [],
    currentCompanyId:          null,
    currentCompanyUrl:         null,
    fetchMessageRequests:      _.noop,
    isMember:                  false,
    markMessagesAsRead:        _.noop,
    messageRequest:            null,
    ownIri:                    null,
    sendMessage:               _.noop,
    sendMessageAttachments:    [],
    sendMessageText:           '',
    setCurrentCompany:         _.noop,
    setCurrentMessageRequest:  _.noop,
    setMessageRequestState:    _.noop,
    setSendMessageAttachments: _.noop,
    setSendMessageText:        _.noop,
    viewingContext:            ViewingContext.UNDEFINED,
};

Screen.renderAffectingProps = Object.keys(Screen.defaultProps);

Screen.renderAffectingStates = [
    'translatedMessages',
];

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        ...CompanyActions,
        ...MachineActions,
        ...OverlayActions,
        ...MessageRequestActions,
    },
    dispatch,
);

const mapStateToProps = (state, ownProps) => {
    const companyUrl             = Navigation.getParameterFromUrl(ownProps, 'companyId');
    const companyId              = Navigation.getCompanyIdFromUrl(ownProps);
    const ownIri                 = StateHelper.getUserCompanyIri(state) || StateHelper.getUserIri(state);
    const messageRequests        = _.get(state, 'messageRequest.messageRequests', []);
    const unreadMessageRequests  = _.get(state, 'messageRequest.unreadMessageRequests', []);
    const messageRequestId       = Navigation.getMessageRequestIdFromUrl(ownProps);
    const sendMessageText        = _.get(state, 'messageRequest.sendMessageText', '');
    const sendMessageAttachments = _.get(state, 'messageRequest.sendMessageAttachments', []);
    const messageRequest         = (
        _.find(
            messageRequests,
            {
                id: messageRequestId,
            },
        ) ||
        _.find(
            unreadMessageRequests,
            {
                id: messageRequestId,
            },
        )
    );
    const viewingContext         = Navigation.getViewingContext(ownProps, state);
    const isMember               = StateHelper.isUserCompanyMember(state);

    return {
        companyTypes:      State.getCompanyValue(state, companyId, 'types', []),
        currentCompanyId:  companyId,
        currentCompanyUrl: companyUrl,
        sendMessageAttachments,
        sendMessageText,
        messageRequest,
        ownIri,
        viewingContext,
        isMember,
    };
};

export default compose(connect(
    mapStateToProps,
    mapDispatchToProps,
))(Screen);
